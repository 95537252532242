import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    geoPermission: false,
    deviceId: null,
    routeLegs: [],
    polylines: [],
    highwayPolylines: [],
    currentPosition: null,
    currentPositionAccuracy: 100,
    start: null,
    destination: null,
    startBatteryLevel: null,
    startAvailableRange: null,
    endBatteryLevel: null,
    endAvailableRange: null,
    hasRapid: false,
    hasNormal: false,
    allDay: false,
    dealer: false,
    chargers: [],
    selectedEv: null,
    batteryLevel: 100,
    rangeAdjustPram: 1.0,
    favedChargers: {},
    cookieConsented: false,
    trackingAllowed: false,
    popBlocked: false,
  },
  mutations: {
    hasRapid(state, hasRapid) {
      state.hasRapid = hasRapid;
    },
    hasNormal(state, hasNormal) {
      state.hasNormal = hasNormal;
    },
    allDay(state, allDay) {
      state.allDay = allDay;
    },
    dealer(state, dealer) {
      state.dealer = dealer;
    },
    currentPosition(state, currentPosition) {
      state.currentPosition = currentPosition;
    },
    currentPositionAccuracy(state, currentPositionAccuracy) {
      state.currentPositionAccuracy = currentPositionAccuracy;
    },
    start(state, start) {
      state.start = start;
    },
    destination(state, destination) {
      state.destination = destination;
    },
    deviceId(state, deviceId) {
      state.deviceId = deviceId;
    },
    routeLegs(state, routeLegs) {
      state.routeLegs = routeLegs;
    },
    polylines(state, polylines) {
      state.polylines = polylines;
    },
    highwayPolylines(state, highwayPolylines) {
      state.highwayPolylines = highwayPolylines;
    },
    routeLegsClear(state) {
      state.routeLegs = null;
    },
    chargers(state, chargers) {
      state.chargers = chargers;
    },
    selectedEv(state, selectedEv) {
      state.selectedEv = selectedEv;
    },
    batteryLevel(state, batteryLevel) {
      state.batteryLevel = batteryLevel;
    },
    startBatteryLevel(state, startBatteryLevel) {
      state.startBatteryLevel = startBatteryLevel;
    },
    startAvailableRange(state, startAvailableRange) {
      state.startAvailableRange = startAvailableRange;
    },
    endBatteryLevel(state, endBatteryLevel) {
      state.endBatteryLevel = endBatteryLevel;
    },
    endAvailableRange(state, endAvailableRange) {
      state.endAvailableRange = endAvailableRange;
    },
    rangeAdjustPram(state, rangeAdjustPram) {
      state.rangeAdjustPram = rangeAdjustPram;
    },
    geoPermission(state, geoPermission) {
      state.geoPermission = geoPermission;
    },
    AddFavedCharger(state, favedCharger) {
      Vue.set(state.favedChargers, `${favedCharger.id}`, favedCharger);
    },
    DeleteFavedCharger(state, charger) {
      Vue.delete(state.favedChargers, `${charger.id}`);
    },
    cookieConsented(state, cookieConsented) {
      state.cookieConsented = cookieConsented;
    },
    trackingAllowed(state, trackingAllowed) {
      state.trackingAllowed = trackingAllowed;
    },
    popBlocked(state, popBlocked) {
      state.popBlocked = popBlocked;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      paths: [
        "selectedEv",
        "favedChargers",
        "geoPermission",
        "favedChargers",
        "cookieConsented",
        "trackingAllowed",
      ],
    }),
  ],
});
