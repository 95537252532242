import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "vuetify/dist/vuetify.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md",
  },
  theme: {
    themes: {
      light: {
        primary: "#1C5A9F",
        secondary: "#1C5A9F",
        accent: "#FFE082",
        error: "#E91E63",
        info: "#4CAFF5",
        success: "#03A9F4",
        warning: "#FF9800",
      },
      dark: {
        primary: "#1C5A9F",
        secondary: "#1C5A9F",
        accent: "#FFE082",
        error: "#E91E63",
        info: "#4CAFF5",
        success: "#03A9F4",
        warning: "#FF9800",
      },
    },
  },
});
