<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({
    //
  }),
  metaInfo: {
    titleTemplate: "%s - EV充電器マップ",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "お近くの電気自動車用充電器を検索！ルートを設定した充電計画作成にご活用ください。電気自動車を便利に安心して利用する手助けに！",
      },
    ],
  },
};
</script>
