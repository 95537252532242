import Vue from "vue";
import VueRouter from "vue-router";
const Application = () => import("@/views/Application.vue");
const LandingPage = () => import("@/views/LandingPage.vue");
const Around = () => import("@/views/Around.vue");
const Guide = () => import("@/views/Guide.vue");
const Favorite = () => import("@/views/Favorite.vue");
const Search = () => import("@/views/Search.vue");
const Info = () => import("@/views/Info.vue");
const TermsOfUse = () => import("@/views/TermsOfUse.vue");
const PrivacyPolicy = () => import("@/views/PrivacyPolicy.vue");
const Update = () => import("@/views/Update.vue");
const Contact = () => import("@/views/Contact.vue");
import { EventBus } from "@/event.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPage",
    component: LandingPage,
  },
  {
    path: "/app",
    component: Application,
    children: [
      {
        path: "",
        redirect: { name: "Around" },
      },
      {
        path: "around",
        name: "Around",
        component: Around,
      },
      {
        path: "guide",
        name: "Guide",
        component: Guide,
      },
      {
        path: "favorite",
        name: "Favorite",
        component: Favorite,
      },
      {
        path: "search",
        name: "Search",
        component: Search,
      },
      {
        path: "info",
        name: "Info",
        component: Info,
        children: [
          {
            path: "terms-of-use",
            name: "TermsOfUse",
            component: TermsOfUse,
          },
          {
            path: "privacy-policy",
            name: "PrivacyPolicy",
            component: PrivacyPolicy,
          },
          {
            path: "update",
            name: "Update",
            component: Update,
          },
          {
            path: "contact",
            name: "Contact",
            component: Contact,
          },
        ],
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "LandingPage" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  if (from.name != null && to.name == "Home") {
    EventBus.$emit("toHome");
  }
});

export default router;
